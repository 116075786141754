export const environment = {
	name: "production",
	production: true,
	apiUrl: "https://api-prod.whatsauto.com.br",
	brokerOfficialWSUrl: "https://broker-ws-official.whatsauto.com.br",
	CDN_URL: "https://cdn.whatsauto.com.br/files",
	chatFlowAppAddress: "https://chatflow.whatsauto.com.br",
	audioConverterUrl: "https://audioconv.whatsauto.com.br/convert",
	brand: "whatsparts",

	facebook: {
		appId: "3081494232012281",
		apiVersion: "v22.0",
		whatsAppConfigId: "2049144535510978",
	},
};
